import type { ChooseClaims } from '../api/auth';

export enum UserRole {
  FULL_ACCESS = 0,
  CONTACT = 2,
  ORDERS = 4,
  NOTIFICATIONS = 5,
  SALES = 7,
  CALENDAR = 9,
  DISCOUNTS = 10,
  ACCOUNTING = 11,
  SETUP = 'setup',
}

export type ItemWithRoles = {
  onlyAdmin: boolean;
  requireRole?: (number | string)[];
};

export const getRoles = (claims: ChooseClaims | null) => {
  let roles: UserRole[] = [];
  if (claims?.roles)
    roles = Array.isArray(claims.roles) ? claims.roles : [claims.roles];

  return roles;
};

const checkRole = (
  rolesToCheck: UserRole[] | undefined,
  claims: ChooseClaims | null
) => {
  if (!rolesToCheck) return true;
  if (!claims) return false;

  if (Array.isArray(claims.roles)) {
    if (claims.roles.includes(UserRole.FULL_ACCESS)) return true;
    return rolesToCheck.some((role) =>
      (claims.roles as UserRole[]).includes(role)
    );
  } else {
    if (claims.roles === UserRole.FULL_ACCESS) return true;
    return rolesToCheck.includes(claims.roles);
  }
};

export const checkRoles = (
  itemWithRoles: ItemWithRoles,
  claims: ChooseClaims | null
) => {
  if (itemWithRoles.onlyAdmin)
    if (claims?.admin || checkRole([UserRole.FULL_ACCESS], claims)) return true;
    else return false;

  if (!itemWithRoles.requireRole || itemWithRoles.requireRole.length === 0)
    return true;

  if (!claims) return false;

  return checkRole(itemWithRoles.requireRole as UserRole[], claims);
};
