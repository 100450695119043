import { useEffect, useState } from 'react';

import type { ChooseClaims } from '../api/auth';
import { useAuth } from '../api/auth';

export const useUserClaims = () => {
  const [claims, setClaims] = useState<ChooseClaims | null>(null);
  const { getTokenResult } = useAuth();

  useEffect(() => {
    getTokenResult((tokenResult) => {
      setClaims(tokenResult.claims as ChooseClaims);
    });
  }, [getTokenResult]);

  return claims;
};
