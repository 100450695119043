import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';

import type { IconType } from '@appchoose/icon';
import Icon from '@appchoose/icon';
import Logo from '@appchoose/logo';
import { Sidebar, SidebarContent } from '@appchoose/sidebar';

import { useAuth } from '../../api/auth';
import { useUserClaims } from '../../hooks/use-user-claims';
import type { ItemWithRoles } from '../../utils/role';
import { UserRole, checkRoles } from '../../utils/role';
import { getUserPicture } from '../../utils/user';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../dropdown-menu';

export type LayoutWithNavbarProps = {
  children: React.ReactNode;
};

type NavigationItem = {
  name: string;
  href: string;
  icon: IconType;
  iconActive: IconType;
} & ItemWithRoles;

export const BASE_URL =
  import.meta.env.REACT_APP_STAGE === 'production'
    ? 'https://meusnidus.appchoose.io'
    : 'https://meusnidus.appchoose.co';

export const LayoutWithNavbar: React.FC<LayoutWithNavbarProps> = ({
  children,
}: LayoutWithNavbarProps) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const { user, signout } = useAuth();
  const claims = useUserClaims();
  const location = useLocation();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const [navigation, setNavigation] = useState<NavigationItem[]>([]);

  useEffect(() => {
    setNavigation(
      (
        [
          {
            name: t('navbar.menu.sales'),
            href: '/ventes',
            icon: 'cart',
            iconActive: 'cartFilled',
            onlyAdmin: false,
            requireRole: [UserRole.SALES],
          },
          {
            name: t('navbar.menu.notifications'),
            href: '/notifications',
            icon: 'notification',
            iconActive: 'notificationFilled',
            onlyAdmin: false,
            requireRole: [UserRole.NOTIFICATIONS],
          },
          {
            name: t('navbar.menu.marketing'),
            href: '/marketing',
            icon: 'gift',
            iconActive: 'gift',
            onlyAdmin: false,
            requireRole: [UserRole.NOTIFICATIONS],
          },
          {
            name: t('navbar.menu.discounts'),
            href: '/discount',
            icon: 'tag',
            iconActive: 'tagFilled',
            onlyAdmin: false,
            requireRole: [UserRole.DISCOUNTS],
          },
          {
            name: t('navbar.menu.profiles'),
            href: `${BASE_URL}/auth`,
            icon: 'profile',
            iconActive: 'profileFilled',
            onlyAdmin: true,
          },
        ] as NavigationItem[]
      ).filter((navigationItem) => checkRoles(navigationItem, claims))
    );
  }, [claims, t]);

  useEffect(() => {
    setMobileMenuOpen(false);
  }, [location]);

  const contextMenu = [
    {
      id: 'logout',
      name: t('navbar.context_menu.logout'),
      onClick: () => signout(() => navigate('', { replace: true })),
    },
  ];

  return (
    <div>
      <Sidebar open={mobileMenuOpen} onOpenChange={setMobileMenuOpen}>
        <SidebarContent left className="flex max-w-xs flex-1 flex-col">
          <div className="h-0 flex-1 overflow-y-auto pb-4 pt-5">
            <div className="flex shrink-0 items-center px-4">
              <Logo
                className="h-8 w-auto"
                appearance={
                  import.meta.env.REACT_APP_STAGE === 'production'
                    ? undefined
                    : 'white'
                }
                role="img"
                aria-label="Choose"
              />
            </div>
            <nav aria-label={t('navbar.sidebar')} className="mt-5">
              <div className="space-y-1 px-2">
                {navigation.map((item) =>
                  !item.href.startsWith('https://') ? (
                    <NavLink
                      key={item.name}
                      to={item.href}
                      className={({ isActive }) =>
                        `group flex items-center rounded-md p-2 text-base font-medium ${
                          isActive ? 'text-gray-900' : 'text-gray-500'
                        }`
                      }
                    >
                      {({ isActive }) => (
                        <>
                          <Icon
                            icon={isActive ? item.iconActive : item.icon}
                            className="mr-4"
                            size="large"
                            aria-hidden="true"
                          />
                          {item.name}
                        </>
                      )}
                    </NavLink>
                  ) : (
                    <a
                      key={item.name}
                      href={item.href}
                      className="group flex items-center rounded-md p-2 text-base font-medium text-gray-500"
                    >
                      <Icon
                        icon={item.icon}
                        className="mr-4"
                        size="large"
                        aria-hidden="true"
                      />
                      {item.name}
                    </a>
                  )
                )}
              </div>
            </nav>
          </div>

          <div className="flex shrink-0 border-t border-gray-200 p-4">
            <DropdownMenu>
              <DropdownMenuTrigger className="group shrink-0">
                <div className="flex items-center text-left">
                  <div>
                    <img
                      className="inline-block size-10 rounded-full"
                      src={user ? getUserPicture(user) : ''}
                      alt=""
                    />
                  </div>
                  <div className="ml-3">
                    <p className="text-base text-gray-700 group-hover:text-gray-900">
                      {user?.displayName}
                    </p>
                    <p className="text-sm font-medium text-gray-500 group-hover:text-gray-700">
                      {t('navbar.account_settings')}
                    </p>
                  </div>
                </div>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="start" className="z-50 min-w-52">
                {contextMenu.map((menuItem) => (
                  <DropdownMenuItem
                    key={menuItem.id}
                    onSelect={menuItem.onClick}
                  >
                    {menuItem.name}
                  </DropdownMenuItem>
                ))}
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </SidebarContent>
      </Sidebar>

      {/* Static sidebar for desktop */}
      <div className="z-20 hidden lg:fixed lg:inset-y-0 lg:flex lg:w-22 lg:flex-col">
        <div className="flex min-h-0 flex-1 flex-col bg-gray-100">
          <div className="flex items-center justify-center pb-12 pt-6">
            <Logo
              appearance={
                import.meta.env.REACT_APP_STAGE === 'production'
                  ? undefined
                  : 'white'
              }
              role="img"
              aria-label="Choose"
            />
          </div>
          <div className="flex flex-1 flex-col overflow-y-auto">
            <nav
              aria-label={t('navbar.sidebar')}
              className="flex flex-col items-center py-6"
            >
              {navigation.map((item) =>
                !item.href.startsWith('https://') ? (
                  <NavLink
                    key={item.name}
                    to={item.href}
                    className={({ isActive }) =>
                      `flex items-center rounded-lg p-4 ${
                        isActive ? 'text-gray-900' : 'text-gray-500'
                      }`
                    }
                  >
                    {({ isActive }) => (
                      <>
                        <Icon
                          icon={isActive ? item.iconActive : item.icon}
                          size="large"
                          aria-hidden="true"
                        />
                        <span className="sr-only">{item.name}</span>
                      </>
                    )}
                  </NavLink>
                ) : (
                  <a
                    key={item.name}
                    href={item.href}
                    className="flex items-center rounded-lg p-4 text-gray-500"
                  >
                    <Icon icon={item.icon} size="large" aria-hidden="true" />
                    <span className="sr-only">{item.name}</span>
                  </a>
                )
              )}
            </nav>
          </div>
          <div className="flex shrink-0 justify-center py-5">
            <DropdownMenu>
              <DropdownMenuTrigger className="shrink-0 rounded-full">
                <img
                  className="mx-auto block size-10 rounded-full"
                  src={user ? getUserPicture(user) : ''}
                  alt=""
                />
                <div className="sr-only">
                  <p>{user?.displayName}</p>
                  <p>{t('navbar.account_settings')}</p>
                </div>
              </DropdownMenuTrigger>

              <DropdownMenuContent align="start" className="min-w-52">
                {contextMenu.map((menuItem) => (
                  <DropdownMenuItem
                    key={menuItem.id}
                    onSelect={menuItem.onClick}
                  >
                    {menuItem.name}
                  </DropdownMenuItem>
                ))}
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </div>
      </div>

      <div className="flex flex-1 flex-col lg:pl-22">
        {/* Mobile top navigation */}
        <div className="lg:hidden">
          <div className="flex items-center justify-between bg-gray-100 px-4 py-2 sm:px-6 lg:px-8">
            <div>
              <Logo
                className="h-8 w-auto"
                appearance={
                  import.meta.env.REACT_APP_STAGE === 'production'
                    ? undefined
                    : 'white'
                }
                role="img"
                aria-label="Choose"
              />
            </div>
            <div>
              <button
                type="button"
                className="-mr-3 inline-flex size-12 items-center justify-center rounded-md text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-900"
                onClick={() => setMobileMenuOpen(true)}
              >
                <span className="sr-only">{t('navbar.open_sidebar')}</span>
                <Icon icon="menu" size="large" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>

        <main className="flex-1">{children}</main>
      </div>
    </div>
  );
};
