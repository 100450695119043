/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import cn from '@appchoose/cn';
import Flag from '@appchoose/flag';
import type { TFunction } from 'i18next';

import type {
  SalesElasticSearchHit,
  SearchSalesV2Query,
} from '../../types/generated';
import {
  convertUtcToLocaleDate,
  formatDate,
  truncateTime,
} from '../../utils/date';
import { getSaleStatusForSale } from '../../utils/sale';

type SalesSearchViewItemProps = {
  displayNoResult?: boolean;
  searchName: string;
  dataSalesOnSearch: SearchSalesV2Query | undefined;
  flag: 'EU' | 'US';
};

export const getSaleStatusColor = (
  status: ReturnType<typeof getSaleStatusForSale>
) => {
  switch (status) {
    case 'CANCELLED':
      return 'text-gray-500';
    case 'HIDDEN':
      return 'text-gray-900';
    case 'ONGOING':
      return 'text-green-600';
    case 'UPCOMING':
      return 'text-orange-600';
    case 'ENDED':
      return 'text-red-600';
  }
};

export const getSaleStatusLabel = (
  status: ReturnType<typeof getSaleStatusForSale>,
  t: TFunction
) => {
  switch (status) {
    case 'CANCELLED':
      return t('sale_page.sale_status.sale_canceled');
    case 'HIDDEN':
      return t('sale_page.sale_status.sale_on_break');
    case 'ONGOING':
      return t('sales_page.search.sales_current');
    case 'UPCOMING':
      return t('sales_page.search.sales_to_come');
    case 'ENDED':
      return t('sales_page.search.sales_finished');
  }
};

export const SalesSearchViewItem: React.FC<SalesSearchViewItemProps> = ({
  displayNoResult,
  searchName,
  dataSalesOnSearch,
  flag,
}: SalesSearchViewItemProps) => {
  const { t, i18n } = useTranslation();

  return (
    <>
      {searchName &&
      dataSalesOnSearch &&
      dataSalesOnSearch?.searchSalesV2?.total !== 0 ? (
        <div className="mb-4 flex items-center px-6 text-xs uppercase text-gray-500">
          <Flag className="mr-2" flag={flag} />
          <span className="font-semibold tracking-wider">
            {t('sales_page.search.result', {
              count: dataSalesOnSearch?.searchSalesV2?.total ?? 0,
            })}
          </span>
          <span className="ml-1">
            (
            {
              dataSalesOnSearch.searchSalesV2?.hits?.filter(
                (sale): sale is SalesElasticSearchHit => !!sale
              ).length
            }
            )
          </span>
        </div>
      ) : null}
      {dataSalesOnSearch?.searchSalesV2?.hits
        ? dataSalesOnSearch.searchSalesV2?.hits
            ?.filter((sale): sale is SalesElasticSearchHit => !!sale)
            .map((search, index) => {
              const status = getSaleStatusForSale(
                truncateTime(new Date(search._source.start_at)).toString(),
                search._source.end_at,
                search._source.store_id,
                search._source.hide,
                search._source.is_cancelled
              );
              return (
                <NavLink key={index} to={`/vente/${search?._id}`}>
                  <div
                    key={search?._id}
                    className="flex flex-col space-y-1 px-6 py-4 hover:bg-green-300"
                  >
                    <div
                      className="text-2xl text-gray-900"
                      dangerouslySetInnerHTML={{
                        __html: search?._source?.brand_name,
                      }}
                    ></div>
                    <div
                      className="text-sm text-gray-700"
                      dangerouslySetInnerHTML={{
                        __html: search?._source?.category
                          ? search._source.category
                          : t('sales_page.search.no_category'),
                      }}
                    ></div>
                    <div className="flex items-center space-x-2">
                      {search?._source?.start_at && search?._source?.end_at ? (
                        <div
                          className={cn('text-sm', getSaleStatusColor(status))}
                        >
                          {getSaleStatusLabel(status, t)}
                        </div>
                      ) : null}
                      <div className="size-1 rounded-full bg-gray-300"></div>
                      {search?._source?.start_at && search?._source?.end_at ? (
                        <div className="text-sm text-gray-700">
                          {formatDate(
                            new Date(search._source.start_at ?? ''),
                            'PP',
                            i18n.language
                          )}{' '}
                          -{' '}
                          {formatDate(
                            convertUtcToLocaleDate(
                              search._source.end_at ?? '',
                              search._source.store_id
                            ),
                            'PP',
                            i18n.language
                          )}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </NavLink>
              );
            })
        : null}
      {displayNoResult ? (
        <div className="mt-6 text-sm text-gray-700">
          {t('sales_page.search.no_result')}
        </div>
      ) : null}
    </>
  );
};
