import { addDays, isFuture, isPast, isWithinInterval } from 'date-fns';
import type { TFunction } from 'i18next';
import MailChecker from 'mailchecker';

import type { CalendarSale } from '../types/calendar-sale';
import { CalendarSaleType } from '../types/calendar-sale';
import type { StoreRegion } from '../types/generated';
import { SaleSetupStatus, SaleStatusFilter } from '../types/generated';
import { convertLocaleDateToUtc, getStoreTimezone } from './date';

export const transformCategory = (
  category: string,
  gender?: number | string
) => {
  let result = '';
  let genderText = '';
  if (typeof gender === 'string') {
    gender = +gender;
  }
  if (typeof gender === 'number') {
    if (gender === 0) {
      genderText = ' (Femme)';
    } else if (gender === 1) {
      genderText = ' (Homme)';
    }
  }
  if (category) {
    result =
      (category.split('>').pop()?.trim().split(' - ').pop()?.trim() ?? '') +
      genderText;
  }

  return result.trim().replace(/(\r\n|\n|\r)/gm, '');
};

export const hasSaleStarted = (startDate?: string) => {
  if (!startDate) return false;

  return isPast(new Date(startDate));
};

export const getErrorReasons = (sale: CalendarSale, t: TFunction) => {
  if (sale.type === CalendarSaleType.PROVISIONAL_SALE) return [];

  const reasons: string[] = [];
  if (!sale.deliver_by_choose && !sale.selection_name) {
    if (!MailChecker.isValid(sale.contactEmail ?? '')) {
      reasons.push(t('calendar.card.no_email_address'));
    }
  }
  if (!sale.image_header) {
    reasons.push(t('calendar.card.no_cover_image'));
  }
  if (!Array.isArray(sale.previews) || sale.previews.length === 0) {
    reasons.push(t('calendar.card.no_preview_image'));
  }

  if (!sale.category) {
    reasons.push(t('calendar.card.no_category'));
  }

  if (sale.hide) {
    reasons.push(t('calendar.card.hidden_sale'));
  }

  if (!sale.brand.about && !sale.description) {
    reasons.push(t('calendar.card.no_brand_description'));
  }

  return reasons;
};

export const getSaleStatus = (sale: CalendarSale) => {
  if (!isPast(sale.start_at)) {
    if (sale.type === CalendarSaleType.PROVISIONAL_SALE) {
      return 'UPCOMING_PROVISIONAL';
    }
    if (
      sale.sale_status === SaleStatusFilter.Cancelled ||
      sale.cancellation_level === 'high'
    ) {
      return 'CANCELLED';
    }
    if (sale.setup_status === SaleSetupStatus.Ready) {
      return 'READY';
    }
    if (sale.setup_status === SaleSetupStatus.SetupWaitingElements) {
      return 'SETUP_WAITING';
    }
    if (
      (Boolean(sale.is_setup) || sale.url_google_drive) ??
      sale.selection_name
    ) {
      return 'SETUP_READY';
    }
    if (sale.setup_status === SaleSetupStatus.SetupReadyOps) {
      return 'SETUP_READY_OPS';
    }
    if (
      !sale.url_google_drive &&
      isWithinInterval(sale.start_at, {
        start: new Date(),
        end: addDays(new Date(), 10),
      })
    ) {
      return 'LATE_CSV';
    }

    return 'UPCOMING';
  }

  if (sale.end_at && isFuture(sale.end_at)) {
    return 'ONGOING';
  } else {
    return 'ENDED';
  }
};

export const getSaleStatusForSale = (
  startAt: string,
  endAt: string,
  storeId: StoreRegion,
  hide: boolean,
  isCancelled: boolean
) => {
  if (isCancelled) {
    return 'CANCELLED';
  }

  if (
    isPast(convertLocaleDateToUtc(new Date(endAt), getStoreTimezone(storeId)))
  ) {
    return 'ENDED';
  }

  if (hide) {
    return 'HIDDEN';
  }

  if (isPast(new Date(startAt))) {
    return 'ONGOING';
  }

  return 'UPCOMING';
};
